import { Modal, Form, Input } from 'antd';
import { useState } from 'react';
import { createRoot } from 'react-dom/client';

const MultiInputModalContent = ({ fields, resolve, reject }) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    try {
      setConfirmLoading(true);
      // This will throw an error if validation fails
      const values = await form.validateFields();
      // Only resolve if we have all required values
      if (Object.values(values).every(value => value !== undefined && value !== '')) {
        resolve(values);
      }
    } catch (error) {
      console.error('Validation failed:', error);
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    reject(new Error('User cancelled'));
  };

  return (
    <Modal
      title="Input Required"
      open={true}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="OK"
      cancelText="Cancel"
      width={400}
      confirmLoading={confirmLoading}
      destroyOnClose
    >
      <Form 
        form={form} 
        layout="vertical"
        validateTrigger="onChange"
      >
        {fields.map((field) => (
          <Form.Item
            key={field.name}
            name={field.name}
            required
            label={
              <span style={{ color: '#666' }}>
                {field.label}:
              </span>
            }
            style={{ marginBottom: 16 }}
            rules={[
              { 
                required: true, 
                message: `Please input ${field.label.toLowerCase()}!`,
                whitespace: true
              }
            ]}
          >
            <Input
              type={field.type || 'text'}
              placeholder={field.placeholder}
              style={{ borderRadius: 4 }}
            />
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};

export const useMultiInputModal = () => {
  return (fields) => {
    return new Promise((resolve, reject) => {
      const container = document.createElement('div');
      document.body.appendChild(container);
      
      const root = createRoot(container);

      const cleanup = () => {
        root.unmount();
        container.remove();
      };

      const wrappedResolve = (value) => {
        cleanup();
        resolve(value);
      };

      const wrappedReject = (reason) => {
        cleanup();
        reject(reason);
      };

      root.render(
        <MultiInputModalContent
          fields={fields}
          resolve={wrappedResolve}
          reject={wrappedReject}
        />
      );
    });
  };
};

// Usage example:
// const useModal = useMultiInputModal();
// 
// const handleClick = async () => {
//   try {
//     const result = await useModal([
//       {
//         name: 'password',
//         label: 'Password',
//         type: 'password',
//         placeholder: 'Enter password to delete'
//       },
//       {
//         name: 'comment',
//         label: 'Comment',
//         type: 'text',
//         placeholder: 'Enter reason for deletion'
//       }
//     ]);
//     console.log('Values:', result);
//   } catch (error) {
//     console.log('Modal was cancelled');
//   }
// };