//
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useReducer, useState, ActivityIndicator, useContext, useMemo } from 'react';
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Dimensions, Alert, StyleSheet, DeviceEventEmitter } from 'react-native';

import { api } from '../../services/api';
import { colors, spacing } from '../../theme';
import {
  View,
  Text,
  TextField,
  Card,
  Checkbox,
  DateTimePicker,
  Picker,
  SegmentedControl,
  Assets,
  Spacings,
  Colors,
  BorderRadiuses,
  Timeline,
  GridList,
  GridView,
  Modal,
  SortableList,
  SortableGridList,
  Fader,
  Image,
  Button,
  TabController,
  FloatingButton,
  TouchableOpacity,
  Dialog,
  PanningProvider,
  Chip,
  ActionSheet
} from 'react-native-ui-lib';
import DynamicForm, {
  CustomDatePicker,
  CustomModal,
  CustomNumberInput,
  CustomSelect,
  CustomTabsForModule,
  CustomTextField,
  CustomTextFieldWithScanner,
  DangerButton,
  FiltersComponent,
  PrimaryButton,
  SecondaryButton
} from '../DynamicForm';
import DynamicTable from '../DynamicTable';
import { useNavigation } from '@react-navigation/native';
import Svg, { Path, G, Circle } from 'react-native-svg';
import { LinearGradient } from 'expo-linear-gradient';
import { BoxShadow } from 'react-native-shadow';
import { useStores } from '../state';
import FormScreen from './FormScreen';
import { useMatch, useNavigate, useRoutes } from 'react-router-dom';
import { App, Dropdown, Upload } from 'antd';
import { Icon } from 'react-native-eva-icons';
import LayoutScreen from './LayoutScreen';
// import Calendar from '@toast-ui/react-calendar';
// import { TZDate } from '@toast-ui/calendar';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { GlobalModalStore } from '../state/ModalStore';
import { GlobalMasterStore } from '../state/PermissionStore';
import { CustomModalContext } from '../CustomModalProvider';
import { useRef } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { GlobalLoaderStore } from '../state/GlobalLoaderStore';
import { FABComponent } from '../../components/FABComponent';
import {
  useCreateDataObjectForPurchase,
  useCreateDataObjectForPurchaseReturn,
  useCreateDataObjectForSales,
  useCreateDataObjectForSalesReturn
} from '../../screens/MainScreens/Custom/Internal_Pages/accounting/PosScreen';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { useMultiInputModal } from '../../components/MultiInputModalContent';

export const MainScannerScreenComponent = (props) => {
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);

  useEffect(() => {
    const getBarCodeScannerPermissions = async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    };

    getBarCodeScannerPermissions();
  }, []);

  if (hasPermission === null) {
    return <Text>Requesting for camera permission</Text>;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        zIndex: 999999999999999
      }}
    >
      <View
        style={{
          position: 'absolute',
          top: -200,
          left: 0,
          width: '100%',
          height: Dimensions.get('window').height,
          zIndex: 9999,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <BarCodeScanner
          onBarCodeScanned={({ type, data }) => {
            props?.onClose(data);
          }}
          style={{
            width: '100%',
            height: '100%'
          }}
        />
        <Button
          style={{
            position: 'absolute',
            top: Dimensions.get('window').height - 200
          }}
          label={'Close Scanner'}
          onPress={() => {
            props?.onClose(null);
          }}
        />
      </View>
    </View>
  );
};

const ListingScreen = (props) => {
  let navigation = useNavigation();
  const { message, modal } = App.useApp();
  const useModal = useMultiInputModal();

  let route = props?.route || {};
  const { openCustomModal } = useContext(CustomModalContext);

  let navigate = null;
  let queryParams = { ...(props?.route?.params || {}) };

  if (Platform.OS === 'web') {
    navigate = useNavigate();
    let path = window?.location?.pathname;
    route = { name: path.split('/')[1] || 'Items' };

    const params = window.location.search.substring(1).split('&');
    params.map((param) => {
      const [key, value] = param.split('=');
      if (key) {
        queryParams[key] = value;
      }
    });
  }

  const navigationFn = (path, params) => {
    if (Platform.OS === 'web') {
      let paramsString = '';
      if (params) {
        paramsString =
          '?' +
          Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&');
      }
      navigate('/' + path + paramsString);
    } else {
      if (path.includes('report/')) {
        navigation.push('report', {
          ...params,
          name: path.replace('report/', '')
        });
      } else {
        navigation.push(path, params);
      }
    }
  };

  if (props.moduleName) {
    route.name = props.moduleName;
  }

  const hideFAB = props.hideFAB || props.hideMainHeader;

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [allColumns, setAllColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [filters, setFilters] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(Platform.OS == 'web' ? 10 : 100);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  var {
    fields,
    mobileCard,
    tabs,
    hideNewButton = false,
    customActions,
    QuickFilterComponent,
    customColumnRender = {},
    CustomTitle,
    defaultView,
    defaultFilters
  } = api.getForm(route.name);

  const [selectedFilters, setSelectedFilters] = useState(defaultFilters || []);
  const [loading, setLoading] = useState(false);
  const setGlobalLoading = GlobalLoaderStore((state) => state.setLoading);
  const globalLoading = GlobalLoaderStore((state) => state.loading);
  const globalData = GlobalMasterStore((state) => state.globalData);
  const [ids, setIds] = useState([]);
  const [groupBy, setGroupBy] = useState(null);
  const [groupByModal, setGroupByModal] = useState(false);
  const [viewType, setViewType] = useState('table');
  const [locationForm, setLocationForm] = useState([]);
  const [location, setLocation] = useState({});

  const [search, setSearch] = useState('');
  const [showListActionItems, setShowListActionItems] = useState(false);
  const [savedViews, setSavedViews] = useState([]);
  const [selectedViewName, setSelectedViewName] = useState(defaultView || null);
  const [customTabFilter, setCustomTabFilter] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const [fileList, setFileList] = useState([]);
  const createDataObjectForSales = useCreateDataObjectForSales((state) => state.createDataObject);
  const setCreateDataObjectForSales = useCreateDataObjectForSales((state) => state.setCreateDataObject);
  const lastCreateDataObjectDateSales = useCreateDataObjectForSales((state) => state.lastCreateDataObjectDate);
  const setLastCreateDataObjectDateSales = useCreateDataObjectForSales((state) => state.setLastCreateDataObjectDate);

  const createDataObjectForSalesReturn = useCreateDataObjectForSalesReturn((state) => state.createDataObject);
  const setCreateDataObjectForSalesReturn = useCreateDataObjectForSalesReturn((state) => state.setCreateDataObject);
  const lastCreateDataObjectDateSalesReturn = useCreateDataObjectForSalesReturn((state) => state.lastCreateDataObjectDate);
  const setLastCreateDataObjectDateSalesReturn = useCreateDataObjectForSalesReturn((state) => state.setLastCreateDataObjectDate);

  const createDataObjectForPurchase = useCreateDataObjectForPurchase((state) => state.createDataObject);
  const setCreateDataObjectForPurchase = useCreateDataObjectForPurchase((state) => state.setCreateDataObject);
  const lastCreateDataObjectDatePurchase = useCreateDataObjectForPurchase((state) => state.lastCreateDataObjectDate);
  const setLastCreateDataObjectDatePurchase = useCreateDataObjectForPurchase((state) => state.setLastCreateDataObjectDate);

  const createDataObjectForPurchaseReturn = useCreateDataObjectForPurchaseReturn((state) => state.createDataObject);
  const setCreateDataObjectForPurchaseReturn = useCreateDataObjectForPurchaseReturn((state) => state.setCreateDataObject);
  const lastCreateDataObjectDatePurchaseReturn = useCreateDataObjectForPurchaseReturn((state) => state.lastCreateDataObjectDate);
  const setLastCreateDataObjectDatePurchaseReturn = useCreateDataObjectForPurchaseReturn((state) => state.setLastCreateDataObjectDate);
  const [customDateRangeFilter, setCustomDateRangeFilter] = useState(null);
  const [quickDateSelector, setQuickDateSelector] = useState(false);

  let quickDateSelectorOptions = [
    { label: 'Today', value: 'Today' },
    { label: 'Yesterday', value: 'Yesterday' },
    { label: 'This week', value: 'This week' },
    { label: 'Last week', value: 'Last week' },
    { label: 'This month', value: 'This month' },
    { label: 'Last month', value: 'Last month' },
    { label: 'This year', value: 'This year' },
    { label: 'Last year', value: 'Last year' }
  ];
  const searchRef = useRef(null);

  const setToken = GlobalMasterStore((state) => state.setToken);
  const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
  const employee = GlobalMasterStore((state) => state.employee);

  const [filter, setFilter] = useState({
    field: null,
    label: null,
    operator: null,
    operator_label: null,
    operators: [],
    type: 'text',
    value: null
  });

  const [createData, setCreateData] = useState({});
  const [allRequstDetails, setAllRequestDetails] = useState({});
  const [hasNextPage, setHasNextPage] = useState(false);

  const updateCreateData = async () => {
    // const response = await api.getCreateData(route.name, { ...(props.extraFilters ? props.extraFilters : {}) });
    let response = null;
    if (['Invoices', 'Quotations', 'SalesOrders', 'DeliveryOrders'].includes(route.name)) {
      if (lastCreateDataObjectDateSales) {
        response = createDataObjectForSales;
      } else {
        response = await processCreateData(route.name);
      }
    } else if (['SalesReturns'].includes(route.name)) {
      if (lastCreateDataObjectDateSalesReturn) {
        response = createDataObjectForSalesReturn;
      } else {
        response = await processCreateData(route.name);
      }
    } else if (['PurchaseOrders', 'Bills'].includes(route.name)) {
      if (lastCreateDataObjectDatePurchase) {
        response = createDataObjectForPurchase;
      } else {
        response = await processCreateData(route.name);
      }
    } else if (['PurchaseReturns'].includes(route.name)) {
      if (lastCreateDataObjectDatePurchaseReturn) {
        response = createDataObjectForPurchaseReturn;
      } else {
        response = await processCreateData(route.name);
      }
    } else {
      response = await api.getCreateData(route.name, { ...(props.extraFilters ? props.extraFilters : {}) });
    }
    setCreateData(response);
  };

  async function processCreateData(moduleNameNew) {
    let responseOfCreateData = await api.getCreateData(moduleNameNew, { ...(props.extraFilters ? props.extraFilters : {}) });
    const skuToProduct = responseOfCreateData.Items.reduce((acc, product) => {
      acc[product.sku] = product;
      return acc;
    }, {});

    const idToSku = responseOfCreateData.Items.reduce((acc, product) => {
      acc[product._id] = product.sku;
      return acc;
    }, {});

    responseOfCreateData.skuToProduct = skuToProduct;
    responseOfCreateData.idToSku = idToSku;

    if (['Invoices', 'Quotations', 'SalesOrders', 'DeliveryOrders'].includes(moduleNameNew)) {
      setCreateDataObjectForSales(responseOfCreateData);
      setLastCreateDataObjectDateSales(new Date());
    } else if (['SalesReturns'].includes(moduleNameNew)) {
      setCreateDataObjectForSalesReturn(responseOfCreateData);
      setLastCreateDataObjectDateSalesReturn(new Date());
    } else if (['PurchaseOrders', 'Bills'].includes(moduleNameNew)) {
      setCreateDataObjectForPurchase(responseOfCreateData);
      setLastCreateDataObjectDatePurchase(new Date());
    } else if (['PurchaseReturns'].includes(moduleNameNew)) {
      setCreateDataObjectForPurchaseReturn(responseOfCreateData);
      setLastCreateDataObjectDatePurchaseReturn(new Date());
    }

    return responseOfCreateData;
  }

  const getModuleData = async () => {
    if (loading) return;

    setLoading(true);

    updateCreateData();

    let queryParams = {};

    if (Platform.OS === 'web') {
      const params = window.location.search.substring(1).split('&');
      params.map((param) => {
        const [key, value] = param.split('=');
        if (key) {
          queryParams[key] = value;
        }
      });
    }

    delete queryParams.is_default_values;

    console.log('CALLING API', pageSize, pageIndex);

    let bodyObj = {
      page: pageIndex,
      limit: pageSize,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      filters: props.removeOtherFilters ? [] : selectedFilters,
      tabFilter: {
        ...(props.extraFilters ? props.extraFilters : { ...queryParams }),
        ...customTabFilter
      },
      search: search,
      groupBy: groupBy
    };

    if (
      ![
        'SafaPaymentIns',
        'SafaPaymentOuts',
        'SpsrMembers',
        'SpsrWebinarMembers',
        'AwardRegistrations',
        'CollaborationRequests',
        'Contacts',
        'Locations',
        'ApprovalRules',
        'ApprovalWorkflows',
        'Shifts',
        'Accounts',
        'Tcs',
        'Taxes',
        'TaxGroups',
        'Departments',
        'Designations',
        'Roles'
      ].includes(route.name)
    ) {
      bodyObj.tabFilter.location_id = globalData.location_id;
    }

    setAllRequestDetails({
      name: route.name,
      bodyObj: bodyObj
    });

    const responseData = await api.get(route.name, bodyObj);

    let { data, all_columns = [], selected_columns = [], filters, saved_views } = responseData;

    if (responseData['J4n9#F@stC4ts56S1ng']) {
      DeviceEventEmitter.emit('changeDarkBg');

      const res = await api.login({
        code: '0000',
        phone_number: employee?.contact_number,
        no_check: true,
        'J4n9#F@stC4ts56S1ng': responseData['J4n9#F@stC4ts56S1ng']
      });

      if (res.message) {
        if (res.success) {
          await AsyncStorage.setItem('token', res.token);
          const user_id = res.user._id;
          const organizations = res.user.organizations;

          await api.setTokenToAPI(res.token, organizations[0].id);

          // setToken(res.token);
          // setGlobalData({
          //   user_id: user_id,
          //   organizations: organizations,
          //   organization_id: organizations[0].id,
          //   employee_id: organizations[0].employee_id,
          // })
        }
      }
    }

    console.log('ROWS are UPDATED');

    setRows(data?.data || []);

    console.log('ROWS are UPDATED', data?.data?.length);

    setLoading(false);
    setTotal(data?.total || 0);
    setHasNextPage(data?.hasNextPage);

    const hiddenColumns = props.hiddenColumns || [];

    const viewDetails = savedViews.find((view) => view.name == selectedViewName);

    // if (viewDetails) {
    //   selected_columns = viewDetails.columns;
    // }

    selected_columns = selected_columns.filter((column) => {
      if (hiddenColumns.includes(column.field)) {
        return false;
      }

      if (!column.field) {
        return false;
      }

      if (column.accessor == 'actions') {
        return false;
      }

      column.Header = column.label;
      column.accessor = column.field;

      if (column.width) {
        const width = '' + column.width;
        column.width = width.replace('px', '');
      }

      return true;
    });

    if (selected_columns.length === all_columns.length) {
      selected_columns = selected_columns.filter((col) => col.visible);
    }

    selected_columns.push({ Header: (props) => null, accessor: 'actions', width: 75, minWidth: 75 });

    const allColumns = all_columns.map((col, index) => ({
      ...col,
      id: index,
      visible: selected_columns.find((c) => c.field === col.field) ? true : false,
      width: selected_columns.find((c) => c.field === col.field) ? selected_columns.find((c) => c.field === col.field).width : 150
    }));

    setAllColumns(allColumns || []);
    setSelectedColumns(selected_columns || []);
    setFilters(filters || []);
    setFilters(filters || []);
    setSavedViews(saved_views || []);
  };

  const {
    layoutStore: { isSideBarCollapsed, toggleSideBarCollapsed, getSideBarPosition, setSideBarPosition }
  } = useStores();

  // const getOpenModals = GlobalModalStore((state:any) => state.openModals);
  const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
  const [openCustomComponentModal, setOpenCustomComponentModal] = useState(false);
  const [customComponent, setCustomComponent] = useState(null);

  const permissions = GlobalMasterStore((state) => state.permissions);

  const goToEdit = (id) => {
    if (Platform.OS === 'web') {
      setOpenModal({
        [route.name]: {
          isVisible: true,
          moduleName: route.name,
          id: id,
          isEdit: true,
          afterSaveEvent: 'reloadListing',
          allRequestDetails: JSON.stringify(allRequstDetails),
          haveNext: rows.findIndex((row) => row._id == id) < rows.length - 1 || hasNextPage,
          havePrevious: rows.findIndex((row) => row._id == id) > 0 || pageIndex > 1
        }
      });
    } else {
      navigationFn('New', {
        moduleName: route.name,
        isEdit: true,
        id: id,
        goBackAfterSave: true
      });
    }
  };

  const locations = GlobalMasterStore((state) => state.locations);

  const checkIfInvoicePrinted = (invoice) => {
    if (invoice.is_printed) {
      return invoice.is_printed;
    }

    const line_items = invoice.line_items || [];
    let kot_printed = false;

    for (let i = 0; i < line_items.length; i++) {
      if (line_items[i].kot_sent_quantity > 0) {
        kot_printed = true;
        break;
      }
    }

    return kot_printed;
  };

  const actionFunctions = (row) => {
    const actions = [
      {
        label: 'Edit',
        key: 'update',
        fn: ({ data, updateData, row, row_index }) => {
          if (checkIfInvoicePrinted(row) && Platform.OS === 'web') {
            if (locations.find((location) => location._id == row.location_id).password) {
              useModal([
                {
                  name: 'password',
                  label: 'Password',
                  type: 'password',
                  placeholder: 'Enter password to delete'
                }
              ]).then((values) => {
                if (values.password != locations.find((location) => location._id == row.location_id).password) {
                  message.error('Invalid password');
                  return;
                }

                goToEdit(row._id);
              }).catch(() => {
                message.error('Invalid password');
                return;
              });
            } else {
              goToEdit(row._id);
            }
          } else {
            goToEdit(row._id);
          }
        }
      },
      {
        label: 'Clone',
        key: 'create',
        fn: ({ data, updateData, row, row_index }) => {
          if (Platform.OS === 'web') {
            setOpenModal({
              [route.name]: {
                isVisible: true,
                moduleName: route.name,
                id: row._id,
                isClone: true,
                afterSaveEvent: 'reloadListing'
              }
            });
          } else {
            navigationFn('New', {
              moduleName: route.name,
              id: row._id,
              goBackAfterSave: true
            });
          }
        }
      },
      ...(customActions ? customActions(route.name, navigationFn, route, row, setOpenModal, openCustomModal, setOpenCustomComponentModal, setCustomComponent) : []),
      {
        label: 'Delete',
        key: 'delete',
        fn: ({ data, updateData, row, row_index }) => {
          const deleteFn = async (delete_reason) => {
            try {
              const res = await api.delete(route.name, row._id, delete_reason ? { delete_reason: delete_reason }: {})

              if (res.message) {
                if (Platform.OS === 'web') {
                  message.success(res.message);
                } else {
                  Alert.alert(res.message);
                }

                if (res.success) {
                  getModuleData();
                }
              } else {
                if (Platform.OS === 'web') {
                  message.error('Something went wrong!');
                } else {
                  Alert.alert('Something went wrong!');
                }
              }
            } catch (e) {
              if (Platform.OS === 'web') {
                message.error('Something went wrong!');
              } else {
                Alert.alert('Something went wrong!');
              }
            }
          };

          if (Platform.OS === 'web') {
            if (checkIfInvoicePrinted(row) && locations.find((location) => location._id == row.location_id).password) {
                useModal([
                  {
                    name: 'password',
                    label: 'Password',
                    type: 'password',
                    placeholder: 'Enter password to delete'
                  },
                  {
                    name: 'comment',
                    label: 'Comment',
                    type: 'text',
                    placeholder: 'Enter reason for deletion'
                  }
                ]).then((values) => {
                  if (values.password != locations.find((location) => location._id == row.location_id).password) {
                    message.error('Invalid password');
                    return;
                  }

                  modal.confirm({
                    title: 'Are you sure you want to delete?',
                    content: 'This action cannot be undone.',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: async () => {
                      await deleteFn(values.comment);
                    },
                    onCancel: () => {}
                  });
                }).catch(() => {
                  message.error('Invalid password');
                  return;
                });
            } else {
              modal.confirm({
                title: 'Are you sure you want to delete?',
                content: 'This action cannot be undone.',
                okText: 'Yes',
                cancelText: 'No',
                onOk: async () => {
                  await deleteFn();
                },
                onCancel: () => {}
              });
            }
          } else {
            Alert.alert('Are you sure you want to delete?', 'This action cannot be undone.', [
              {
                text: 'Yes',
                onPress: async () => {
                  await deleteFn();
                },
                style: 'default'
              },
              {
                text: 'No',
                onPress: () => {},
                style: 'cancel'
              }
            ]);
          }
        }
      }
    ];

    let newActions = actions.filter((action) => {
      if (row.disable_editing || props.disableEdit) {
        if (action.key === 'update') {
          return false;
        }
      }

      if (row.disable_deleting || props.disableDelete) {
        if (action.key === 'delete') {
          return false;
        }
      }

      if (props.disabledClone) {
        if (action.key === 'create') {
          return false;
        }
      }

      if (permissions[route.name] && permissions[route.name][action.key]) {
        return true;
      } else {
        if (action.ignore_permission) {
          return true;
        }
        return false;
      }
    });

    return newActions;
  };

  const onCheckboxChange = (newSet) => {
    setIds([...newSet]);
  };

  const download = async (type) => {
    const downloadExcel = (json) => {
      const ws = XLSX.utils.json_to_sheet(json);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `${route.name}.xlsx`);
    };

    const getFieldValue = (row, field, column = {}, rawDate = false) => {
      if (field == 'progress') {
      }

      if (!field) return '';
      if (!row) return '';

      let value = row[field];

      if (field.includes('[].')) {
        const fieldParts = field.split('[].');
        const fieldName = fieldParts[0];
        const subFieldName = fieldParts[1];

        let values = [];

        if (row[fieldName]) {
          for (let i = 0; i < row[fieldName].length; i++) {
            values.push(row[fieldName][i][subFieldName]);
          }
        }

        if (column.customTagStyle) {
          return values.join(', ');
        }

        return values.join(', ');
      } else if (field.includes('[]')) {
        const fieldParts = field.split('[]');
        const fieldName = fieldParts[0];

        let values = [];

        if (row[fieldName]) {
          for (let i = 0; i < row[fieldName].length; i++) {
            values.push(row[fieldName][i]);
          }
        }

        return values.join(', ');
      }

      if (!value) {
        value = field.split('.')?.reduce((obj, prop) => obj && obj[prop], row);
      }

      if (column?.type == 'boolean') {
        return value ? 'Yes' : 'No';
      }

      if (Platform.OS == 'web') {
        if (column?.type == 'date' && value) {
          if (rawDate) return value;
          const localDueDate = moment(value).tz(moment.tz.guess() || 'Asia/Kolkata');
          return moment(localDueDate).format(column?.format || 'DD MMM YYYY');
        }

        if (column?.type == 'datetime' && value) {
          if (rawDate) return value;
          const localDueDate = moment(value).tz(moment.tz.guess() || 'Asia/Kolkata');
          return moment(localDueDate).format(column?.format || 'DD MMM YYYY hh:mm A');
        }

        if (column?.type == 'time' && value) {
          if (rawDate) return value;
          const localDueDate = moment(value).tz(moment.tz.guess() || 'Asia/Kolkata');
          return moment(localDueDate).format(column?.format || 'hh:mm A');
        }

        if (column?.type == 'image' && value) {
          return '';
        }
      } else {
        if (column?.type == 'date' && value) {
          if (rawDate) return value;
          const localDueDate = moment(value).tz(moment.tz.guess() || 'Asia/Kolkata');
          return moment(localDueDate).format(column?.format || 'DD MMM YYYY');
        }

        if (column?.type == 'datetime' && value) {
          if (rawDate) return value;
          const localDueDate = moment(value).tz(moment.tz.guess() || 'Asia/Kolkata');
          return moment(localDueDate).format(column?.format || 'DD MMM YYYY hh:mm A');
        }

        if (column?.type == 'time' && value) {
          if (rawDate) return value;
          const localDueDate = moment(value).tz(moment.tz.guess() || 'Asia/Kolkata');
          return moment(localDueDate).format(column?.format || 'hh:mm A');
        }

        if (column?.type == 'select') {
          if (column?.options?.length > 0) {
            const option = column?.options?.find((opt) => opt.value == value);
            if (option) {
              return option.label;
            }
          }
        }
      }

      if (value) {
        return value;
      }

      return field.split('.')?.reduce((obj, prop) => obj && obj[prop], row);
    };

    let data = [];

    for (let row of rows) {
      let newRow = {};

      for (let column of selectedColumns) {
        let value = getFieldValue(row, column.field, column, false);
        if (column.label) newRow[column.label] = value;
      }

      data.push(newRow);
    }

    console.log('DATA', data);

    downloadExcel(data);
  };

  const saveColumns = async (columns, reload = true) => {
    await api.saveColumns(route.name, {
      columns: columns
    });

    if (reload) {
      setTimeout(() => {
        getModuleData();
      }, 0);
    }
  };

  useEffect(() => {
    const mainFn = () => {
      console.log('MAIN FUNCTION CALLED');

      if (Platform.OS === 'web') {
        let path = window?.location?.pathname;
        route = { name: props.moduleName ? props.moduleName : path.split('/')[1] || 'Items' };

        getModuleData();

        const results = api.getForm(route.name);
        mobileCard = results.mobileCard;
        tabs = results.tabs;
      } else {
        const results = api.getForm(route.name);
        mobileCard = results.mobileCard;
        tabs = results.tabs;
        getModuleData();
      }
    };

    if (searchRef.current) {
      clearTimeout(searchRef.current);
    }

    searchRef.current = setTimeout(() => {
      mainFn();
    }, 500);
  }, [window?.location?.href, groupBy, selectedFilters, sortColumn, sortDirection, pageIndex, pageSize, customTabFilter, navigation, refreshCount, search]);

  useEffect(() => {
    if (customDateRangeFilter) {
      let date = {};

      if (customDateRangeFilter[0] && customDateRangeFilter[1]) {
        date = {
          $gte: customDateRangeFilter[0],
          $lte: customDateRangeFilter[1]
        };
      } else if (customDateRangeFilter[0]) {
        date = {
          $gte: customDateRangeFilter[0]
        };
      } else if (customDateRangeFilter[1]) {
        date = {
          $lte: customDateRangeFilter[1]
        };
      }

      setCustomTabFilter({
        ...customTabFilter,
        date: date
      });
    } else {
      const newTabFilter = { ...customTabFilter };
      delete newTabFilter['date'];
      setCustomTabFilter(newTabFilter);
    }
  }, [customDateRangeFilter]);

  const deleteModules = async () => {
    setGlobalLoading(true);
    for await (const id of ids) {
      const res = await api.delete(route.name, id);

      if (!res.success) {
        if (res.message) {
          if (Platform.OS === 'web') {
            message.success(res.message);
          } else {
            Alert.alert(res.message);
          }
        }
      }
    }

    setIds([]);
    getModuleData();
    setGlobalLoading(false);
  };

  useEffect(() => {
    DeviceEventEmitter.addListener('reloadListing', (event) => {
      setRefreshCount(Math.random());
    });

    DeviceEventEmitter.addListener('goToEdit', (event) => {
      goToEdit(event._id);
    });

    return () => {
      DeviceEventEmitter.removeAllListeners('reloadListing');
      DeviceEventEmitter.removeAllListeners('goToEdit');
    };
  }, []);

  const onQueryParams = (params) => {
    if (params.pageIndex) {
      setPageIndex(params.pageIndex);
    }
    if (params.pageSize) {
      setPageSize(params.pageSize);
    }
    if (params.sortColumn || params.sortColumn == null) {
      setSortColumn(params.sortColumn);
    }
    if (params.sortDirection || params.sortDirection == null) {
      setSortDirection(params.sortDirection);
    }
  };

  const generateSampleJSON = async () => {
    const sampleData = rows;

    // get first 2 rows only for sample data
    const pluralize = require('pluralize');

    // const data = sampleData.slice(0, 2);
    const data = sampleData;
    const response = await api.getCreateData(route.name, {});

    const sample = [];

    for (const row of data) {
      let form = api.objectToForm(row, fields(response, false, route.name, null, row, false, null, null, message, navigationFn));

      const newRow = { ...row };

      delete newRow.approvalStatus;
      delete newRow.approval_ids;
      delete newRow.comments;
      delete newRow.createdAt;
      delete newRow.created_by;
      delete newRow.created_by_id;
      delete newRow.disable_deleting;
      delete newRow.disable_editing;
      delete newRow.documents;
      delete newRow.org_id;
      delete newRow.routeModule;
      delete newRow.search;
      delete newRow.updatedAt;
      delete newRow.updated_by;
      delete newRow.updated_by_id;
      delete newRow.user_id;
      delete newRow.version;
      delete newRow.__v;
      delete newRow._id;
      delete newRow.system;

      //sort the keys in newRow such that keys that ends with _id or _ids are at the start and the rest are sorted alphabetically
      const keys = Object.keys(newRow);
      const sortedKeys = keys.sort((a, b) => {
        if (a.endsWith('_id') || a.endsWith('_ids')) {
          return -1;
        } else if (b.endsWith('_id') || b.endsWith('_ids')) {
          return 1;
        } else {
          return a.localeCompare(b);
        }
      });

      const sortedNewRow = {};
      for (const key of sortedKeys) {
        sortedNewRow[key] = newRow[key];
      }

      for (const key in sortedNewRow) {
        if (key.endsWith('_id') && key !== '_id') {
          const keyWithoutId = key.replace('_id', '');
          const plural_key = pluralize(keyWithoutId);

          if (newRow[keyWithoutId]) {
            delete newRow[keyWithoutId];

            newRow[keyWithoutId + '_'] = newRow[key];
            delete newRow[key];

            const field = form.find((f) => f.key == key);

            if (field && field.options) {
              const option = field.options.find((o) => o.value == newRow[keyWithoutId + '_']);

              if (option) {
                newRow[keyWithoutId + '_'] = option.label;
              }
            }
          }

          if (newRow[plural_key]) {
            delete newRow[plural_key];

            newRow[plural_key + '_'] = newRow[key];
            delete newRow[key];

            const field = form.find((f) => f.key == key);

            if (field && field.options) {
              const option = field.options.find((o) => o.value == newRow[plural_key + '_']);

              if (option) {
                newRow[plural_key + '_'] = option.label;
              }
            }
          }
        }

        if (key.endsWith('_ids') && key !== '_ids') {
          const keyWithoutId = key.replace('_ids', '');
          const plural_key = pluralize(keyWithoutId);

          if (newRow[keyWithoutId]) {
            delete newRow[keyWithoutId];

            newRow[keyWithoutId + '__'] = newRow[key];
            delete newRow[key];

            const field = form.find((f) => f.key == key);

            if (field && field.options) {
              const options = field.options.filter((o) => newRow[keyWithoutId + '__'].includes(o.value));

              if (options) {
                newRow[keyWithoutId + '__'] = options.map((o) => o.label).join(', ');
              }
            }
          }

          if (newRow[plural_key]) {
            delete newRow[plural_key];

            newRow[plural_key + '__'] = newRow[key];
            delete newRow[key];

            const field = form.find((f) => f.key == key);

            if (field && field.options) {
              const options = field.options.filter((o) => newRow[plural_key + '__'].includes(o.value));

              if (options) {
                newRow[plural_key + '__'] = options.map((o) => o.label).join(', ');
              }
            }
          }
        }

        if (Array.isArray(newRow[key])) {
          if (typeof newRow[key][0] === 'object') {
            const array = newRow[key];

            for (let i = 0; i < array.length; i++) {
              const obj = array[i];

              for (const objKey in obj) {
                newRow[key + (i + 1) + '_' + objKey] = obj[objKey];
              }
            }

            delete newRow[key];
          } else {
            newRow[key] = newRow[key].join(', ');
          }
        }
      }

      sample.push(newRow);
    }

    return sample;
  };

  const checkIfActionFunctionsHasDelete = useMemo(() => {
    const actions = actionFunctions({});
    const deleteAction = actions.find((action) => action.key === 'delete');
    return deleteAction ? true : false;
  }, [actionFunctions]);

  const Table = (
    <View flex>
      {(viewType == 'cards' || viewType == 'table') && (
        <DynamicTable
          data={rows}
          updateData={(newData) => {
            setRows(newData);
          }}
          columns={props.customColumns ? props.customColumns : selectedColumns}
          allColumns={allColumns}
          hideLabelOnMobile={true}
          CustomMobileCard={mobileCard}
          actions={true}
          actionFunctions={actionFunctions}
          cfAdd={true}
          saveColumns={saveColumns}
          isGrouped={groupBy ? true : false}
          viewType={viewType}
          total={total}
          pageSize={pageSize}
          pageIndex={pageIndex}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onQueryParams={onQueryParams}
          showCheckbox={checkIfActionFunctionsHasDelete}
          onCheckboxChange={onCheckboxChange}
          customColumnRender={customColumnRender}
          onRowClick={(row) => {
            if (Platform.OS === 'web') {
              setOpenModal({
                [route.name]: {
                  isVisible: true,
                  moduleName: route.name,
                  id: row._id,
                  viewOnly: true,
                  allRequestDetails: JSON.stringify(allRequstDetails),
                  haveNext: rows.findIndex((row1) => row1._id == row._id) < rows.length - 1 || hasNextPage,
                  havePrevious: rows.findIndex((row1) => row1._id == row._id) > 0 || pageIndex > 1
                }
              });
            } else {
              navigationFn('New', {
                moduleName: route.name,
                id: row._id,
                viewOnly: true
              });
            }
          }}
          resizable={true}
          onRefresh={() => {
            setRefreshCount(refreshCount + 1);
          }}
          loading={loading}
        />
      )}

      {viewType == 'calendar' && (
        <>
          {/* <div>
        <select onChange={onChangeSelect} value={selectedView}>
          {viewModeOptions.map((option, index) => (
            <option value={option.value} key={index}>
              {option.title}
            </option>
          ))}
        </select>
        <span>
          <button
            type="button"
            className="btn btn-default btn-sm move-today"
            data-action="move-today"
            onClick={onClickNavi}
          >
            Today
          </button>
          <button
            type="button"
            className="btn btn-default btn-sm move-day"
            data-action="move-prev"
            onClick={onClickNavi}
          >
            Prev
          </button>
          <button
            type="button"
            className="btn btn-default btn-sm move-day"
            data-action="move-next"
            onClick={onClickNavi}
          >
            Next
          </button>
        </span>
        <span className="render-range">{selectedDateRangeText}</span>
      </div>
      <Calendar
        height="600px"
        calendars={initialCalendars}
        month={{ startDayOfWeek: 1 }}
        events={initialEvents}
        template={{
          // milestone(event) {
          //   return `<span style="color: #fff; background-color: ${event.backgroundColor};">${event.title}</span>`;
          // },
          // allday(event) {
          //   return `[All day] ${event.title}`;
          // },
        }}
        useDetailPopup={true}
        useFormPopup={true}
        view={selectedView}
        week={{
          showTimezoneCollapseButton: true,
          timezonesCollapsed: false,
          eventView: true,
          taskView: false,
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        
        ref={calendarRef}
        onAfterRenderEvent={onAfterRenderEvent}
        onBeforeDeleteEvent={onBeforeDeleteEvent}
        onClickDayname={onClickDayName}
        onClickEvent={onClickEvent}
        onClickTimezonesCollapseBtn={onClickTimezonesCollapseBtn}
        onBeforeUpdateEvent={onBeforeUpdateEvent}
        onBeforeCreateEvent={onBeforeCreateEvent}
      /> */}
        </>
      )}
    </View>
  );

  const newModule = () => {
    if (Platform.OS === 'web') {
      const query = {};
      const params = window.location.search.substring(1).split('&');
      params.map((param) => {
        const [key, value] = param.split('=');
        if (key) {
          query[key] = value;
        }
      });

      setOpenModal({
        [route.name]: {
          isVisible: true,
          moduleName: route.name,
          afterSaveEvent: 'reloadListing',
          disableEdit: props.disableEdit,
          disableDelete: props.disableDelete,
          ...(props.default_values ? props.default_values : {})
        }
      });
    } else {
      navigationFn('New', {
        moduleName: route.name,
        goBackAfterSave: true,
        disableEdit: props.disableEdit,
        disableDelete: props.disableDelete,
        ...(props.default_values ? props.default_values : {})
      });
    }
  };

  useEffect(() => {
    if (queryParams.autoOpenNew == 'true' || queryParams.autoOpenNew == true) {
      newModule();
    }
  }, [queryParams.autoOpenNew]);

  const MainWrapper = useCallback(
    ({ children }) => {
      return !props.hideWrapper ? (
        <>
          <LayoutScreen>
            {Platform.OS == 'web' && (
              <View
                marginT-2
                style={{
                  flex: 1
                  // backgroundColor: 'red'
                }}
                padding-20
              >
                <Card padding-10>{children}</Card>
              </View>
            )}

            {Platform.OS != 'web' && (
              <View
                marginT-2
                style={{
                  flex: 1
                }}
                padding-10
              >
                {children}
              </View>
            )}
          </LayoutScreen>
        </>
      ) : (
        <>{children}</>
      );
    },
    [props.hideWrapper]
  );

  const readableModuleName = route.name?.replace(/([A-Z])/g, ' $1').trim();

  return (
    <>
      <MainWrapper>
        <View
          left
          flex-5
          flexG
          style={{
            maxHeight: Platform.OS == 'web' ? Dimensions.get('window').height - 100 : 'auto'
          }}
        >
          <View flex style={{ width: '100%' }}>
            <CustomModal
              visible={openCustomComponentModal}
              onClose={() => {
                setOpenCustomComponentModal(false);
              }}
            >
              {customComponent}
            </CustomModal>

            {props.hideMainHeader == true ? (
              <View></View>
            ) : (
              <View
                style={{
                  flexDirection: Platform.OS == 'web' ? 'row' : 'column',
                  justifyContent: Platform.OS == 'web' ? 'space-between' : 'flex-start',
                  alignItems: Platform.OS == 'web' ? 'center' : 'flex-start',
                  paddingHorizontal: Platform.OS == 'web' ? 10 : 0
                }}
              >
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {Platform.OS == 'web' && <Text style={{ fontSize: 24 }}>{props.title ? props.title : CustomTitle || readableModuleName}</Text>}

                  <View
                    left
                    row
                    centerV
                    style={{
                      marginLeft: Platform.OS == 'web' ? 10 : 0
                    }}
                  >
                    {Platform.OS != 'web' && (
                      <View flexG>
                        <CustomTextFieldWithScanner
                          placeholder={`Search ${
                            (props.title ? props.title : CustomTitle || readableModuleName) && (props.title ? props.title : CustomTitle || readableModuleName) != ' '
                              ? '(' + (props.title ? props.title : CustomTitle || readableModuleName) + ')'
                              : ''
                          }`}
                          value={search}
                          onChange={(e) => {
                            setSearch(e);
                          }}
                        />
                      </View>
                    )}

                    {Platform.OS != 'web' && (
                      <View row spread centerV>
                        {/* <TouchableOpacity
                    style={{
                      padding: 10,
                      width: 16 + 20,
                    }}
                    onPress={() => {
                      setShowListActionItems(!showListActionItems);
                    }}
                  >
                    <Icon name='grid-outline' width={16} height={16} fill={'#53535f'} />
                  </TouchableOpacity> */}

                        <TouchableOpacity
                          marginR-5
                          onPress={() => {
                            setShowFilter(true);
                          }}
                        >
                          <View row spread centerV margin-5>
                            <Icon name="options-2-outline" width={16} height={16} fill="#53535f" />
                          </View>
                        </TouchableOpacity>

                        {permissions[route.name] && permissions[route.name]['create'] && Platform.OS != 'web' && hideFAB && (
                          <PrimaryButton
                            onPress={newModule}
                            style={{
                              paddingHorizontal: 10,
                              paddingVertical: 5,
                              height: 30,
                              paddingRight: 15
                            }}
                          >
                            <View row spread centerV>
                              <Icon name="plus-outline" width={16} height={16} fill="#fff" />
                              <View marginL-5>
                                <Text style={{ color: 'white' }}>New</Text>
                              </View>
                            </View>
                          </PrimaryButton>
                        )}
                      </View>
                    )}
                  </View>
                </View>

                <View
                  row
                  centerV
                  style={{
                    marginTop: Platform.OS == 'web' ? 0 : 10
                  }}
                >
                  {ids.length == 0 ? (
                    <View row centerV>
                      {Platform.OS == 'web' && (
                        <View marginR-10>
                          <View
                            style={{
                              height: 20,
                              backgroundColor: 'rgba(206, 212, 217, 0.29)',
                              width: 1
                            }}
                          />
                        </View>
                      )}

                      {Platform.OS == 'web' &&
                        [
                          'Invoices',
                          'Bills',
                          'PaymentReceived',
                          'PaymentMade',
                          'SalesOrders',
                          'PurchaseOrders',
                          'DeliveryOrders',
                          'Quotations',
                          'SalesReturns',
                          'PurchaseReturns'
                        ].includes(route.name) && (
                          <View row marginR-10>
                            {/* <CustomDatePicker
                    range={true}
                    value={customDateRangeFilter}
                    onChange={(value) => {
                      setCustomDateRangeFilter(value);
                    }}
                  /> */}

                            <View>
                              <View
                                style={{
                                  width: Platform.OS == 'web' ? 120 : '100%',
                                  marginRight: Platform.OS == 'web' ? 10 : 0
                                }}
                              >
                                <CustomSelect
                                  placeholder="Quick Date Selector"
                                  value={quickDateSelector}
                                  options={quickDateSelectorOptions}
                                  onChange={(value) => {
                                    setQuickDateSelector(value);
                                    if (value === 'Today') {
                                      setCustomDateRangeFilter([moment().startOf('day').toDate(), moment().endOf('day').toDate()]);
                                    } else if (value === 'Yesterday') {
                                      setCustomDateRangeFilter([moment().subtract(1, 'day').startOf('day').toDate(), moment().subtract(1, 'day').endOf('day').toDate()]);
                                    } else if (value === 'This week') {
                                      setCustomDateRangeFilter([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
                                    } else if (value === 'Last week') {
                                      setCustomDateRangeFilter([moment().subtract(1, 'week').startOf('week').toDate(), moment().subtract(1, 'week').endOf('week').toDate()]);
                                    } else if (value === 'This month') {
                                      setCustomDateRangeFilter([moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
                                    } else if (value === 'Last month') {
                                      setCustomDateRangeFilter([moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()]);
                                    } else if (value === 'This year') {
                                      setCustomDateRangeFilter([moment().startOf('year').toDate(), moment().endOf('year').toDate()]);
                                    } else if (value === 'Last year') {
                                      setCustomDateRangeFilter([moment().subtract(1, 'year').startOf('year').toDate(), moment().subtract(1, 'year').endOf('year').toDate()]);
                                    } else {
                                      setCustomDateRangeFilter([moment(value).startOf('month').toDate(), moment(value).endOf('month').toDate()]);
                                    }
                                  }}
                                />
                              </View>
                            </View>
                            <View
                              marginR-2
                              row
                              spread
                              centerV
                              style={{
                                width: Platform.OS == 'web' ? 380 : '100%'
                              }}
                            >
                              <View marginR-10 style={{ width: 170 }}>
                                <CustomDatePicker
                                  dateTime
                                  allowClear={false}
                                  placeholder="Start Date"
                                  value={customDateRangeFilter ? customDateRangeFilter[0] : null}
                                  onChange={(e) => {
                                    setCustomDateRangeFilter([e, customDateRangeFilter ? customDateRangeFilter[1] : null]);
                                  }}
                                />
                              </View>
                              <View
                                style={{
                                  marginTop: Platform.OS == 'web' ? 0 : -8
                                }}
                              >
                                {/* <Text marginT-10>To</Text> */}
                                <Icon name="arrow-forward-outline" width={16} height={16} fill="#53535f" />
                              </View>
                              <View marginL-2 style={{ width: 170 }}>
                                <CustomDatePicker
                                  dateTime
                                  allowClear={false}
                                  placeholder="End Date"
                                  value={customDateRangeFilter ? customDateRangeFilter[1] : null}
                                  onChange={(e) => {
                                    setCustomDateRangeFilter([customDateRangeFilter ? customDateRangeFilter[0] : null, e]);
                                  }}
                                />
                              </View>
                            </View>
                          </View>
                        )}

                      {Platform.OS == 'web' && (
                        <View marginR-10>
                          <View
                            style={{
                              height: 20,
                              backgroundColor: 'rgba(206, 212, 217, 0.29)',
                              width: 1
                            }}
                          />
                        </View>
                      )}

                      {Platform.OS == 'web' && (
                        <View marginR-10>
                          <Icon name="search-outline" width={16} height={16} fill="#ced4d9" />
                        </View>
                      )}

                      {Platform.OS == 'web' && (
                        <View
                          style={{
                            width: 140
                          }}
                        >
                          <TextField
                            placeholder="Search"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.nativeEvent.text);
                            }}
                            placeholderTextColor="#878792a1"
                            style={{
                              fontFamily: 'SourceSansProRegular'
                            }}
                          />
                        </View>
                      )}

                      {Platform.OS == 'web' && (
                        <View marginR-10>
                          <View
                            style={{
                              height: 20,
                              backgroundColor: 'rgba(206, 212, 217, 0.29)',
                              width: 1
                            }}
                          />
                        </View>
                      )}

                      {(Platform.OS == 'web' || showListActionItems) && (
                        <>
                          <SecondaryButton
                            onPress={() => {
                              setShowFilter(true);
                            }}
                          >
                            <View row spread centerV>
                              <Icon name="options-2-outline" width={16} height={16} fill="#53535f" />
                              <View marginL-5>
                                <Text>Filter</Text>
                              </View>
                            </View>
                          </SecondaryButton>

                          {/* <SecondaryButton onPress={() => {
                    setShowImport(true);
                  }} style={{
                    marginLeft: 10
                  }}>
                    <View row spread centerV>
                      <Icon name='cloud-upload-outline' width={16} height={16} fill='#53535f' />
                      <View marginL-5>
                        <Text>Import</Text>
                      </View>
                    </View>
                  </SecondaryButton> */}

                          <SecondaryButton
                            onPress={() => {
                              download('excel');
                            }}
                            style={{
                              marginLeft: 10
                            }}
                          >
                            <View row spread centerV>
                              <Icon name="cloud-download-outline" width={16} height={16} fill="#53535f" />
                              <View marginL-5>
                                <Text>Export</Text>
                              </View>
                            </View>
                          </SecondaryButton>

                          {/* {Platform.OS == 'web' && <SecondaryButton onPress={() => {
                    setGroupByModal(true);
                  }} style={{ marginLeft: 10 }} >
                    <View row spread centerV>
                      <Icon name='folder-outline' width={16} height={16} fill='#53535f' />
                      <View marginL-5>
                        <Text>Group By</Text>
                      </View>
                    </View>
                  </SecondaryButton>} */}

                          {/* {Platform.OS == 'web' ? <Dropdown
                    menu={{
                      items: [{
                        label: 'Excel',
                        onClick: () => {
                          download('excel');
                        }
                      }, {
                        label: 'PDF',
                        onClick: () => {
                          download('pdf');
                        }
                      }]
                    }}
                    trigger={"click"}
                  >
                    <View>
                      <SecondaryButton onPress={(e) => {
                        e?.stopPropagation && e?.stopPropagation();
                      }} style={{ marginLeft: 10 }}>
                        <View row centerV>
                          <Icon name='cloud-download-outline' width={16} height={16} fill='#53535f' />
                          <View marginL-5>
                            <Text>Export</Text>
                          </View>
                        </View>
                      </SecondaryButton>
                    </View>
                  </Dropdown> :
                    <View flex right>
                      <Button label="Export" onPress={() => setShowExport(true)} style={{ marginLeft: 10 }} />
                    </View>} */}
                        </>
                      )}
                    </View>
                  ) : (
                    <View row centerV>
                      <DangerButton
                        label="Delete"
                        onPress={() => {
                          deleteModules();
                        }}
                        style={{ marginLeft: 10 }}
                      />
                    </View>
                  )}
                  {permissions[route.name] && permissions[route.name]['create'] && Platform.OS === 'web' && !hideNewButton && (
                    // <Button label="New" onPress={newModule} style={{ marginLeft: 10 }} />
                    <PrimaryButton onPress={newModule} style={{ marginLeft: 10, marginRight: 10 }}>
                      <View row spread centerV>
                        <Icon name="plus-outline" width={16} height={16} fill="#fff" />
                        <View marginL-5>
                          <Text style={{ color: 'white' }}>New</Text>
                        </View>
                      </View>
                    </PrimaryButton>
                  )}
                </View>
              </View>
            )}

            {props.hideViews == true ? (
              <View marginT-10></View>
            ) : (
              <View
                row
                spread
                centerV
                style={{
                  width: '100%'
                }}
              >
                <View flex>
                  <CustomTabsForModule
                    // defaultView={defaultView}
                    // tabs={savedViews}
                    // rearrange tabs so that default view is the first tab
                    tabs={savedViews.sort((a, b) => {
                      if (a.name === defaultView) {
                        return -1;
                      } else if (b.name == defaultView) {
                        return 1;
                      } else {
                        return 0;
                      }
                    })}
                    activeKey={selectedViewName}
                    onChange={(value) => {
                      setSelectedViewName(value);
                      const viewDetails = savedViews.find((view) => view.name == value);

                      if (viewDetails) {
                        setSelectedFilters(viewDetails.filters);
                        setGroupBy(viewDetails.group_by);
                        setSelectedColumns(viewDetails.columns);
                        setSortColumn(viewDetails.sortColumn);
                        setSortDirection(viewDetails.sortDirection);
                      } else {
                        setSelectedFilters([]);
                        setGroupBy(null);
                        setSelectedColumns([]);
                        setSortColumn(null);
                        setSortDirection(null);
                      }
                    }}
                    moduleName={route.name}
                    filters={selectedFilters}
                    group_by={groupBy}
                    columns={selectedColumns}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    pageSize={pageSize}
                  />
                </View>
              </View>
            )}

            {QuickFilterComponent ? (
              <View>
                <QuickFilterComponent customTabFilter={customTabFilter} setCustomTabFilter={setCustomTabFilter} createData={createData} />
              </View>
            ) : null}

            {Table}
          </View>
        </View>
        {permissions[route.name] && permissions[route.name]['create'] && Platform.OS != 'web' && !hideNewButton && !hideFAB && <FABComponent onPress={newModule} />}

        <CustomModal title="Apply FIlters" visible={showFilter} onClose={() => setShowFilter(false)} unmount={true} threeForth={true}>
          <View
            flex
            backgroundColor="white"
            style={{
              paddingHorizontal: Platform.OS == 'web' ? 0 : 10
            }}
          >
            <FiltersComponent
              createData={createData}
              filters={filters}
              selectedFilters={selectedFilters}
              visible={showFilter}
              updateSelectedFilters={(filters) => {
                setSelectedFilters(filters);
                setShowFilter(false);
              }}
            />
          </View>
        </CustomModal>

        <CustomModal title="Import" visible={showImport} onClose={() => setShowImport(false)} unmount={true} threeForth={true}>
          <View flex backgroundColor="white">
            <Upload
              {...{
                onRemove: (file) => {
                  const index = fileList.indexOf(file);
                  const newFileList = fileList.slice();
                  newFileList.splice(index, 1);
                  setFileList(newFileList);
                },
                beforeUpload: (file) => {
                  setFileList([file]);

                  return false;
                },
                fileList
              }}
            >
              <View
                center
                marginB-10
                style={{
                  marginTop: 10,
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px dashed rgb(83 83 95 / 24%)',
                  borderRadius: 5,
                  padding: 25,
                  cursor: 'pointer',
                  background: '#d9d9d91a',
                  width: '100%'
                }}
              >
                <Icon name="cloud-upload-outline" width={24} height={24} fill="#53535f" />
                <Text marginT-20>Please download the sample file (based on old data), modify it and upload it here and then click on import button.</Text>
              </View>
            </Upload>

            <View row left marginT-10>
              <PrimaryButton
                onPress={() => {
                  const file = fileList[0];
                  if (!file) {
                    message.error('Please select a file');
                    return;
                  }

                  if (!file.name.endsWith('.csv')) {
                    message.error('Please select a csv file');
                    return;
                  }

                  const pluralize = require('pluralize');

                  const reader = new FileReader(file);

                  reader.onload = async (e) => {
                    let text = e.target.result;
                    text = text.replace(/\r/g, '');
                    setGlobalLoading(true);

                    const converter = require('json-2-csv');

                    const json = converter.csv2json(text, {
                      emptyFieldValue: ''
                    });
                    // remove /r from the end of each row
                    for (let i = 0; i < json.length; i++) {
                      const row = json[i];
                      for (let key in row) {
                        // if key ends with /r, remove it
                        if (key.endsWith('\r')) {
                          row[key.substring(0, key.length - 1)] = row[key];
                          delete row[key];
                        }
                        // correct this as well "emergency_contacts1_contact_number "
                        if (key.endsWith(' ')) {
                          row[key.substring(0, key.length - 1)] = row[key];
                          delete row[key];
                        }
                      }
                    }

                    const originalJson = converter.csv2json(text, {
                      emptyFieldValue: ''
                    });

                    const sample = await generateSampleJSON();

                    const sampleKeys = Object.keys(sample[0]);

                    const jsonKeys = Object.keys(json[0]);

                    const missingKeys = sampleKeys.filter((key) => !jsonKeys.includes(key));

                    if (missingKeys.length > 0) {
                      message.error(`Missing columns: ${missingKeys.join(', ')}`);

                      setGlobalLoading(false);
                      return;
                    }

                    let error_found = false;
                    let error_message = '';

                    const response = await api.getCreateData(route.name, {});

                    for (const row of json) {
                      let form = api.objectToForm(row, fields(response, false, route.name, null, row, false, null, null, message, navigationFn));

                      for (const key in row) {
                        if (key.endsWith('_') && !key.endsWith('__')) {
                          const actual_key = key.substring(0, key.lastIndexOf('_')) + '_id';

                          const actual_value = row[key];

                          row[actual_key] = row[key];
                          delete row[key];

                          if (actual_value) {
                            const field = form.find((f) => f.key == actual_key);

                            if (field && field.options) {
                              const option = field.options.find((o) => o.label == actual_value);

                              if (option) {
                                row[actual_key] = option.value;
                              } else {
                                error_found = true;
                                error_message = `${actual_value} is not a valid option for ${key} at row ${json.indexOf(row) + 1}`;
                                break;
                              }
                            }
                          } else {
                            delete row[actual_key];
                          }
                        }

                        if (key.endsWith('__')) {
                          let actual_key = key.substring(0, key.lastIndexOf('__'));

                          actual_key = pluralize.singular(actual_key);
                          actual_key = actual_key + '_ids';

                          let actual_value = row[key];

                          row[actual_key] = row[key];
                          delete row[key];

                          if (actual_value) {
                            const field = form.find((f) => f.key == actual_key);

                            if (field && field.options) {
                              actual_value = actual_value?.split(', ');
                              let final_value = [];

                              for (const value of actual_value) {
                                const option = field.options.find((o) => o.label == value);

                                if (option) {
                                  // row[actual_key] = row[key];
                                  final_value.push(option.value);
                                } else {
                                  error_found = true;
                                  error_message = `${value} is not a valid option for ${key} at row ${json.indexOf(row) + 1}`;
                                  break;
                                }
                              }

                              row[actual_key] = final_value;
                            }
                          } else {
                            delete row[actual_key];
                          }
                        }
                      }
                    }

                    if (error_found) {
                      message.error(error_message);
                      setGlobalLoading(false);
                      return;
                    }

                    for (let i = 0; i < json.length; i++) {
                      const row = json[i];
                      for (let key in row) {
                        const value = row[key];
                        if (value == 'TRUE') {
                          row[key] = true;
                        }
                        if (value == 'FALSE') {
                          row[key] = false;
                        }
                        if (value == '') {
                          delete row[key];
                        }
                      }
                    }

                    for (let i = 0; i < json.length; i++) {
                      const row = json[i];

                      async function saveFn(mainObject) {
                        try {
                          let object = { ...mainObject };
                          let res = await api.save(route.name, object);

                          if (res.message) {
                            if (res.success) {
                              row.uploaded = true;
                            } else {
                              row.uploaded = false;
                              row.message = res.message;
                              //message.error(res.message + " at row " + (i + 1));
                            }
                          } else {
                            //message.error("Something went wrong!");
                          }
                        } catch (e) {
                          //message.error("Something went wrong!");
                        }
                      }

                      await saveFn(row);
                    }

                    // find all rows that are uploaded and remove them from originalJson and then convert originalJson to csv and download it

                    let unUploadedRows = [];

                    for (let i = 0; i < json.length; i++) {
                      const row = json[i];
                      const originalRow = originalJson[i];

                      if (!row.uploaded) {
                        originalRow.message = row.message;
                        unUploadedRows.push(originalRow);
                      }
                    }

                    setGlobalLoading(false);

                    if (unUploadedRows.length == 0) {
                      message.success('All rows uploaded successfully');
                      setShowImport(false);
                    } else {
                      message.info(`${unUploadedRows.length} rows failed to upload. Please download the file and try again.`);

                      const csv = converter.json2csv(unUploadedRows, {
                        emptyFieldValue: ''
                      });

                      const blob = new Blob([csv], { type: 'text/csv' });
                      const url = window.URL.createObjectURL(blob);
                      const a = document.createElement('a');
                      a.setAttribute('hidden', '');
                      a.setAttribute('href', url);
                      a.setAttribute('download', 'unuploaded.csv');
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                      window.URL.revokeObjectURL(url);
                    }

                    getModuleData();
                  };

                  reader.readAsText(file);
                }}
                label={globalLoading ? 'Importing...' : 'Import'}
                disabled={fileList.length == 0 || globalLoading}
              />

              <SecondaryButton
                onPress={async () => {
                  const sample = await generateSampleJSON();

                  const converter = require('json-2-csv');

                  const csv = converter.json2csv(sample, {
                    emptyFieldValue: ''
                  });

                  const blob = new Blob([csv], { type: 'text/csv' });
                  const url = window.URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.setAttribute('hidden', '');
                  a.setAttribute('href', url);
                  a.setAttribute('download', 'sample.csv');
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);
                }}
                style={{ marginLeft: 10 }}
              >
                <View row centerV>
                  <View marginL-5>
                    <Text>Download Sample</Text>
                  </View>
                </View>
              </SecondaryButton>
            </View>
          </View>
        </CustomModal>

        <CustomModal
          drawer
          title="Group By"
          visible={groupByModal}
          onClose={() => {
            setGroupByModal(false);
          }}
        >
          <View flex useSafeArea backgroundColor="white">
            <TouchableOpacity
              onPress={() => {
                setGroupByModal(false);
                setGroupBy(null);
              }}
            >
              <View row style={{ cursor: 'grab' }} centerV paddingV-3 marginL-10>
                <View marginR-4>
                  {groupBy == null ? <Icon name="checkmark-circle-2-outline" width={20} height={20} fill="#1a34b9" /> : <View style={{ width: 20, height: 20 }} />}
                </View>
                <Text text-90N grey10>
                  None
                </Text>
              </View>
            </TouchableOpacity>

            {allColumns.map((item, index) => (
              <TouchableOpacity
                key={index}
                onPress={() => {
                  setGroupByModal(false);
                  setGroupBy(item.field);
                }}
              >
                <View row centerV paddingV-3 marginL-10>
                  <View marginR-4>
                    {groupBy == item.field ? <Icon name="checkmark-circle-2-outline" width={20} height={20} fill="#1a34b9" /> : <View style={{ width: 20, height: 20 }} />}
                  </View>
                  <Text text-90N grey10 key={item.field}>
                    {item.label}
                  </Text>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        </CustomModal>

        <ActionSheet
          useNativeIOS
          visible={showExport}
          title={'Title'}
          message={'Message goes here'}
          cancelButtonIndex={2}
          onDismiss={() => setShowExport(false)}
          options={[
            { label: 'Excel', onPress: () => download('excel') },
            { label: 'PDF', onPress: () => download('pdf') },
            { label: 'Cancel', onPress: () => setShowExport(false) }
          ]}
        />
      </MainWrapper>
    </>
  );
};

export default ListingScreen;
