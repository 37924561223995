export default {
  // API_URL: "http://192.168.29.17:8081",
  // API_URL: "http://192.168.29.17:8081",

  API_URL: "https://accounting-new.inkapps.io",
  // HOST_URL: "affy-demo.inkapps.io",

  // API_URL: "OFFLINE",
  HOST_URL: "affy-demo.inkapps.io",
}
