// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, TouchableOpacity } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, DangerButton, Row } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../../../mycomponents/CRUD/LayoutScreen"
import FormScreen from "../../../../../mycomponents/CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore"

export const accountingReportModules = [{
    name: "Inventory",
    reports: [
        "Inventory Summary",
        // "Inventory Detail By Item",
        // "Location Report",
        // "Available Serial Numbers",
        // "Available Batches",
        // "Item Transaction Report",
    ],
}, {
    name: "Sales",
    reports: [
        // "Ageing Receipt",
        // "Ageing Summary",
        "Deleted Invoices",
        "Changed Invoices After Print",
        "Captain Summary Report",
        "Decreased Item Quantity After KOT Printed",
        "Deleted Item After KOT Printed",
        "Customer Balance",
        "Sales By Customer",
        "Sales By Item",
        "Sale By Item Category",
        "Sales Register",
        // "Day Wise Collection",
    ],
}, {
    name: "Purchases",
    reports: [
        // "Ageing Bills",
        // "Ageing Bills Summary",
        "Supplier Balance",
        "Purchase By Supplier",
        "Purchase By Item",
        "Purchase Register",
    ],
},
// {
//     name: "Taxes",
//     reports: [
//         "GSTR 1",
//         "GSTR 3B",
//         "GSTR 2 Reconciliation",
//     ],
// }, 
{
    name: "Accounts",
    reports: [
        // "Day Book",
        // "Day Book By Location",
        // "Day Book By Employee",
        // "Payment Collection By Employee",
        "Journal Entries",
        "Trial Balance",
        "Profit And Loss",
        "Balance Sheet",
        "Ledger",
        "Detailed Ledger",
    ],
}]

export const accountingReportModules15 = [{
    name: "Inventory",
    reports: [
        "Inventory Summary",
        // "Inventory Detail By Item",
        "Location Report",
        "Available Serial Numbers",
        "Available Batches",
        "Item Transaction Report",
    ],
}, {
    name: "Sales",
    reports: [
        "Deleted Invoices",
        "Changed Invoices After Print",
        // "Ageing Summary",
        "Customer Balance",
        "Sales By Customer",
        "Sales By Item",
        "Sale By Item Category",
        "Sales Register",
        // "Day Wise Collection",
    ],
}, {
    name: "Purchases",
    reports: [
        // "Ageing Bills",
        // "Ageing Bills Summary",
        "Supplier Balance",
        "Purchase By Supplier",
        "Purchase By Item",
        "Purchase Register",
    ],
},
// {
//     name: "Taxes",
//     reports: [
//         "GSTR 1",
//         "GSTR 3B",
//         "GSTR 2 Reconciliation",
//     ],
// }, 
{
    name: "Accounts",
    reports: [
        "Day Book",
        "Day Book By Location",
        "Day Book By Employee",
        "Payment Collection By Employee",
        "Journal Entries",
        "Trial Balance",
        "Profit And Loss",
        "Balance Sheet",
        "Ledger",
        "Detailed Ledger",
    ],
}]

const AccountingReportsScreen = observer(function AccountingReportsScreen(props) {
    let navigation = useNavigation();
    let navigate = null;

    const organization_id = GlobalMasterStore((state) => state.globalData.organization_id);

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const headingStyle = { width: '100%', padding: 10, marginBottom: 10 };

    return (
        <LayoutScreen>
            <View flex>
                <ScrollView>
                    <Card margin-20>
                        <View padding-15 margin-10 marginB-0 style={{ borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0 }}>
                            <View spread row>
                                <Text h1>Reports</Text>
                            </View>
                        </View>
                        <View marginL-10 paddingH-15 paddingT-5>
                            <Row>
                                {(organization_id == 15 ? accountingReportModules15 : accountingReportModules).map((reportModule, index) => {
                                    return (
                                        <Col key={index} width={Platform.OS === 'web' ? '33.33%' : '100%'}>
                                            <Card style={headingStyle}>
                                                <Text darkCardTitle>
                                                    {reportModule.name}
                                                </Text>
                                            </Card>

                                            {reportModule.reports.map((report, index) => {
                                                return (<TouchableOpacity style={{
                                                    paddingHorizontal: 20,
                                                    paddingVertical: 5,
                                                }}
                                                    onPress={() => {
                                                        if (Platform.OS === 'web') {
                                                            navigationFn("report/" + report.replace(/ /g, ""), null)
                                                        } else {
                                                            const obj = { name: report.replace(/ /g, "") };
                                                            navigationFn("report", obj)
                                                        }
                                                    }}
                                                >
                                                    <Text lightCardTitle key={index} marginT-5 dark10>
                                                        {report}
                                                    </Text>
                                                </TouchableOpacity>
                                                )
                                            })}
                                        </Col>
                                    )
                                })}
                            </Row>
                        </View>
                    </Card>
                </ScrollView>
            </View>
        </LayoutScreen>
    )
})

export default AccountingReportsScreen

const $container = {
    flex: 1,
}